h1 {
    color: white;
    font-size: 2rem;
    padding-left: 1.8rem;
    padding-right: 1.8rem;
}

.questionWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    width: 100%;
    min-height:auto;
}

@media only screen and (min-width: 0px) {
    .optionsContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .optionButton {
        width: 40%;
        height: 100px;
        margin: 0.1rem;
        padding: 1rem;
        font-weight: bold;
        font-size: 1.4em;
        border-radius: 2rem;
    }
}

@media only screen and (min-width: 480px) {

    .optionButton {
        width: 40%;
        margin: 0.5rem;
        font-size: 2em;
        height: 160px;
    }
}

@media only screen and (min-width: 1024px) {
    .optionsContainer {
        flex-wrap: nowrap;
    }
    .optionButton {
        width: 22%;
        margin: 1rem;
        font-size: 2em;
        height: 160px;
    }
}

.btnRed {
    color: #fff;
    background-color: rgb(165, 49, 49);
    border-color: rgb(165, 49, 49);
}

.btnRed:hover {
    background-color: rgb(197, 72, 72);
    border-color: rgb(197, 72, 72);
}

.btnRed:active {
    background-color: rgb(197, 72, 72);
    border-color: rgb(197, 72, 72);
}

.btnRed:visited {
    background-color: rgb(197, 72, 72);
    border-color: rgb(197, 72, 72);
}

.btnOrange {
    color: #fff;
    background-color: rgb(253, 123, 36);
    border-color: rgb(253, 123, 36);
}

.btnOrange:hover {
    background-color: rgb(252, 145, 74);
    border-color: rgb(252, 145, 74);
}

.btnOrange:active {
    background-color: rgb(252, 145, 74);
    border-color: rgb(252, 145, 74);
}

.btnOrange:visited {
    background-color: rgb(252, 145, 74);
    border-color: rgb(252, 145, 74);
}

.btnYellow {
    color: #fff;
    background-color: rgb(255, 200, 49);
    border-color: rgb(255, 200, 49);
}

.btnYellow:hover {
    background-color: rgb(255, 213, 98);
    border-color: rgb(255, 213, 98);
}

.btnYellow:active {
    background-color: rgb(255, 213, 98);
    border-color: rgb(255, 213, 98);
}

.btnYellow:visited {
    background-color: rgb(255, 213, 98);
    border-color: rgb(255, 213, 98);
}

.btnGreen {
    color: #fff;
    background-color: rgb(113, 179, 69);
    border-color: rgb(113, 179, 69);
}

.btnGreen:hover {
    background-color: rgb(136, 211, 85);
    border-color: rgb(136, 211, 85);
}

.btnGreen:active {
    background-color: rgb(136, 211, 85);
    border-color: rgb(136, 211, 85);
}

.btnGreen:visited {
    background-color: rgb(136, 211, 85);
    border-color: rgb(136, 211, 85);
}

.btnExcellent {
    color: white;
    background-color:rgb(0, 148, 167);
    border-color: rgb(0, 148, 167);
}

.btnExcellent:hover {
    background-color: rgb(47, 213, 235);
    border-color: rgb(47, 213, 235);
}

.btnExcellent:active {
    background-color: rgb(47, 213, 235);
    border-color: rgb(47, 213, 235);
}

.btnExcellent:visited {
    background-color: rgb(47, 213, 235);
    border-color: rgb(47, 213, 235);
}