body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.date-picker-wrapper {
  position: relative !important;
}

.date-input {
  width: 100% !important;
}

.date-placeholder {
  position: absolute !important;
  top: 50% !important;
  left: 10px !important;
  transform: translateY(-50%) !important;
  pointer-events: none !important;
  transition: top 0.3s, font-size 0.3s !important;
  color: gray;
}

.date-input.has-value + .date-placeholder {
  top: 0 !important;
}

.form-control{
  border: none !important;
  box-sizing: border-box !important;
  border-bottom: 2px solid #ccc !important;
  border-right: 3px solid #ccc !important;
}